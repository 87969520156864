import { Container } from 'rsuite';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Footer from '../../components/PageLayout/Footer';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import Panel from '../../components/Panel';
import SEO from '../../components/Seo';

const Publications = ({ data }) => (
  <>
    <SEO
      title="Publications"
      description="Publications"
      path="publications"
    />
    <div className="marginTopTitle">
      <h1 className="titleSeparate">Publications</h1>
      <Panel type="publications" data={data} />
    </div>
  </>
);

Publications.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  {
    allTag(
      sort: { fields: [count], order: DESC },
      filter: { publications: { eq: true } }
    ) {
      edges {
        node {
          name
          color
          path
        }
      }
    }
    allMdx(
      filter: { 
        fileAbsolutePath: { regex: "/publications\/.*\/index\\.mdx?$/" }
      }
      sort: { fields: [frontmatter___priority, frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            date
            venue
            authors
            path
            title
            tags
            excerpt
            priority
            links {
              name
              url
            }
          }
          fileAbsolutePath
        }
      }
    }
  }
`;

export default Publications;
